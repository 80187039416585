/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import { ThemeProvider, audiDarkTheme } from '@audi/audi-ui-react';
import { ProviderProps as AppProps } from '@oneaudi/oneaudi-os-react';
import React from 'react';
import { VideoPlayerConfig } from '../types';
import { VideoPlayer } from './components/VideoPlayer/VideoPlayer';
import { debug } from './utils/debugging';

interface FeatureAppProps extends Omit<AppProps, 'config'> {
  config?: VideoPlayerConfig;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ config }: FeatureAppProps) => {
  if (!config) {
    throw new Error('No config provided!');
  }

  if (typeof config.videos === 'undefined') {
    throw new Error('Bad config: `videos` is mandatory');
  }

  if (typeof config.videos.xs === 'undefined') {
    throw new Error('Bad config: `videos.xs` is mandatory');
  }

  debug(`config: ${JSON.stringify(config)}`);

  return (
    <ThemeProvider theme={audiDarkTheme}>
      <VideoPlayer
        cover={config.cover}
        isFormatable={config.isFormatable}
        playsInline={config.playsInline}
        videos={config.videos}
      />
    </ThemeProvider>
  );
};

export default FeatureApp;
