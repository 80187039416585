import * as React from 'react';

// import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import {
  ButtonFullSizeWrapper,
  MuteButton,
  MuteButtonWrapper,
  PlayButton,
  ReplayButton,
} from '../button';

interface IController {
  featureAppId: string;
  isMuted: boolean;
  // play: Pick<AudiVideoServiceInterfaceV1, 'play'>;
  play: () => Promise<void>;
  // replay: Pick<AudiVideoServiceInterfaceV1, 'play'>;
  replay: (consumerId?: string) => Promise<void>;
  showPlayButton: boolean;
  showReplayButton: boolean;
  showToggleMuteButton: boolean;
  toggleMuted: () => void;
}

export const Controller: React.FC<IController> = ({
  featureAppId,
  isMuted,
  play,
  replay,
  showPlayButton,
  showReplayButton,
  showToggleMuteButton,
  toggleMuted,
}) => {
  return (
    <>
      {showPlayButton && (
        <ButtonFullSizeWrapper
          onClick={() => {
            // eslint-disable-next-line no-void
            void play();
          }}
        >
          <PlayButton />
        </ButtonFullSizeWrapper>
      )}
      {showReplayButton && (
        <ButtonFullSizeWrapper
          onClick={() => {
            // eslint-disable-next-line no-void
            void replay();
          }}
        >
          <ReplayButton />
        </ButtonFullSizeWrapper>
      )}
      {showToggleMuteButton && (
        <MuteButtonWrapper id={`${featureAppId}-videoAppMuteButtonWrapper`}>
          <MuteButton isMuted={isMuted} toggleMuted={toggleMuted} />
        </MuteButtonWrapper>
      )}
    </>
  );
};
