/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';

import { VideoPlayerConfig } from '../types';
import ContextProvider from './Context';
import App from './FeatureApp';
import { isTrackingDisabled } from './utils/debugging';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:logger': Logger;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'audi-video-service': AudiVideoServiceInterfaceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
      'audi-video-service': '1.0.0',
      's2:async-ssr-manager': '^1.0.0',
    },
  },
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '*',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '*',
    },
  },

  create: ({
    config,
    featureServices,
    featureAppId,
    featureAppName,
  }: FeatureAppEnvironment<FeatureServiceDependencies, VideoPlayerConfig>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const trackingService = !isTrackingDisabled()
      ? featureServices['audi-tracking-service']
      : undefined;
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
      trackingService.featureAppId = featureAppId;
    }

    const videoService = featureServices['audi-video-service'];

    return {
      render: () => {
        return (
          <ContextProvider
            featureAppEnv={{ featureAppId, featureAppName }}
            loggerService={loggerService}
            trackingService={trackingService}
            videoService={videoService}
          >
            <App id="featureAppId" config={config} />
          </ContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
